<template>
  <div>
    <!-- <base-header>
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Tombola del éxito</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header> -->

    <div class="container-fluid mt-4">     
      <card >
        <loading-overlay :active.sync="loading" :is-full-page="true" />
        <template slot="header">
            <div class="row">
              <div class="col-6">
                <h3 class="mb-0 text-primary">Elegir ganadores</h3>
              </div>
              <div class="col-6 text-right">
               <!--  <base-button
                  :disabled="loading"
                  @click="openModal()"
                  type="info"
                  icon
                  size="sm"
                >
                  <span class="btn-inner--icon"
                    ><i class="fas fa-file-excel"></i
                  ></span>
                  <span class="btn-inner--text">Exportar</span>
                </base-button> -->
              </div>
            </div>
          </template>

        <div class="row justify-content-between">
          <div class="col  col-md-10 col-sm-6">
            <div class="row px-2">

             <base-input label="Pais:" class="mb-0 pr-1">
            <el-select
              v-if="!loading1"
              v-model="country"
              filterable
              placeholder="Elige un país"
            >
              <el-option
                v-for="option in countries"
                :key="option.id"
                :label="option.name"
                :value="option.name"
              >
              </el-option>
            </el-select>

            <div v-else style="height: 50px" class="vld-parent">
              <loading-overlay
                :height="30"
                :active.sync="loading1"
                :is-full-page="false"
              />
            </div>
          </base-input>

          <base-input label="Fase:" class="mb-0 pl-1">
            <el-select
              v-if="!loading1"
              v-model="cutoff_date"
              filterable
              placeholder="Elige una fase"
            >
              <el-option
                v-for="op in cutoffdates"
                :key="op.id"
                :label="op.phase"
                :value="op.id"
              >
              </el-option>
            </el-select>
            <div v-else style="height: 50px" class="vld-parent">
              <loading-overlay
                :height="30"
                :active.sync="loading1"
                :is-full-page="false"
              />
            </div>
          </base-input>
          

          
          <base-input 
           v-if="!loading1"
          label="Cantidad:"
          class="mb-0 pl-2"
          type="number"
          min="1"
          v-model="ganadores"
          max="10"
          />
          

           <base-input label=" " class="mb-0 pt-2">
            <base-button
              type="button"
              class="btn btn-primary"
              native-type="submit"
              :disabled="validate_sorteo"
              @click="getWinners()"
            >
              Sortear
            </base-button>
          </base-input>






            </div>
          </div>
          <!-- <div class="col col-md-2 col-sm-6">
            <div class="row justify-content-end mx-2 ">
              <img
                src="../../../../public/img/clave-del-exito.png"
                class="tam-img"
              />
            </div>
          </div> -->
        </div>









        <div class="container-flid">
          <div class="row">
            <div class="mx-auto">
              <h2 class="text-primary text-center">GANADORES DE LA TOMBOLA</h2>
            </div>
          </div>

          <div class="card">
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item"
                v-for="(winner, index) in winners"
                :key="index"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <a class="avatar rounded-circle">
                      <span>{{ index + 1 }}</span>
                    </a>
                  </div>

                  <div class="col ml--2">
                    <h4 class="mb-0">
                      <a href="#!"
                        ><span>{{ winner.name }} </span></a
                      >
                    </h4>
                    <small>Facturas subidas: {{ winner.bills_count }}</small>
                  </div>

                  <div lass="col-auto">
                    <el-tooltip content="Descalificar" placement="top">
                      <a
                        @click="askDisqualification(index, winner.id)"
                        class="btn btn-danger btn-sm m-1"
                        data-toggle="tooltip"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </el-tooltip>
                  </div>
                  <div lass="col-auto">
                    <el-tooltip content="Contactar" placement="top">
                      <a
                        @click="openModal(winner)"
                        class="btn btn-primary btn-sm m-1"
                        data-toggle="tooltip"
                      >
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </a>
                    </el-tooltip>
                  </div>
                  <div lass="col-auto">
                    <el-tooltip content="Ver historial" placement="top">
                      <a
                        :href="'/admin/bills-user/' + winner.id"
                        target="_blank"
                        class="btn btn-info btn-sm m-1 mr-3"
                        data-toggle="tooltip"
                        style="cursor: pointer"
                      >
                        <i class="fas fa-file-invoice" />
                      </a>
                    </el-tooltip>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="row px-2">
          <div class="col">
            <div class="float-right">
              <base-button
                @click="askPostWinners()"
                type="button"
                class="btn btn-success"
                native-type="submit"
                :disabled="validate"
              >
                Guardar
              </base-button>
            </div>
          </div>
        </div>
      </card>

      <!-- Modal para mostrar los datos del ususario ganador -->
      <modal :show.sync="modal">
        <h6 slot="header" class="modal-title">Información de contacto</h6>

        <div class="row">
          <div class="col-12">
            <p><strong class="font-weight-bold">Nombre:</strong> {{ usuario.name }}</p>
          </div>
          <div class="col-12">
            <p><strong class="font-weight-bold">Email:</strong> {{ usuario.email }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <p><strong class="font-weight-bold">Identificación:</strong> {{ usuario.identification }}</p>
          </div>
          <div class="col-12">
            <p><strong class="font-weight-bold">Teléfono:</strong> {{ usuario.tel }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <p><strong class="font-weight-bold">Dirección:</strong> {{ usuario.address }}</p>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>
<script>
/* import { BasePagination } from "@/components/"; */
import { Tooltip, Select, Option } from "element-ui";
/* import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb"; */
import { Modal } from "@/components";
/* import flatPicker from "vue-flatpickr-component"; */
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es";
import XLSX from "xlsx";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  layout: "DashboardLayout",

  components: {
    /* BasePagination, */
    [Tooltip.name]: Tooltip,
    [Select.name]: Select,
    [Option.name]: Option,
    /* RouteBreadCrumb, */
    Modal,
    /* flatPicker, */
  },

  data() {
    return {
      config: {
        altFormat: "d M, Y",
        altInput: true,
        allowInput: true,
        dateFormat: "m-d-Y",
        locale: Spanish,
      },
      loading: false,
      loading1: false,
      countries: [],
      cutoffdates: [],
      cutoff_date: null,
      country: null,
      winners: [],
      winner: [],
      count: 0,
      //contacto user
      usuario: {
        name: "",
        email: "",
        identification: "",
        tel: "",
        address: "",
      },
      modal: false,
      //variables para la descalificaion de usuairos
      //excluidos: [],
      nuevo: null,
      ganadores:10,
    };
  },

  computed: {
    validate_sorteo: function () {
      return !this.country || !this.cutoff_date || !this.ganadores;
    },
    validate: function () {
      return !this.winners.length > 0;
    },
  },

  methods: {
    openModal(row) {
      this.usuario.name = row.name;
      this.usuario.email = row.email;
      this.usuario.tel = row.tel;
      this.usuario.identification = row.identification;
      this.usuario.address = row.address;
      this.modal = true;
    },
    async getCutOffDate() {
      this.loading1 = true;
      await this.$store.dispatch("cutOffDate/list").then(() => {
        this.cutoffdates = this.$store.getters["cutOffDate/list"];
      });
      this.loading1 = false;
    },
    async getCountries() {
      this.loading1 = true;
      await this.$store.dispatch("countries/list").then(() => {
        this.countries = this.$store.getters["countries/list"];
      });
      this.loading1 = false;
    },
    async getWinners() {
      this.loading = true;
      if(this.ganadores>10){
        this.ganadores=10;
      }
      try {
        const params = {
          country: this.country,
          cutOffDate: this.cutoff_date,
          winners: this.ganadores,
        };
        await this.$store.dispatch("tombola/list", params).then(() => {
          this.winners = this.$store.getters["tombola/list"];
          this.$store.dispatch("tombola/vaciar_excluidos");
          if (this.winners.length === 0) {
            swal.fire({
              title: `¡Atención!`,
              text: "No se puede generar a ningún ganador.",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              icon: "warning",
            });
          }
          if(this.winners.length < this.ganadores){
             swal.fire({
              title: `!No hay suficientes ganadores, para cubrir la cantidad solicidata!`,
              text: `Debido a que no hay suficientes usuarios, ya ganaron o esten descalificados, el sistema solo pudo generar a ${this.winners.length} ganadores.`,
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              icon: "warning",
            });
          }
        });
        this.loading = false;
      } catch (error) {
        console.log('====================================');
        console.log(error);
        console.log('====================================');
        await this.$store.dispatch("errors/sendError", {
            plataforma: "Tombola del éxito / Clavulin",
            metodo: "Get winners",
            error: JSON.stringify(error.response.data),
            data: JSON.stringify(this.winners)
        });
        this.loading = false;
        this.$notify({
          type: "danger",
          message: `Lo sentimos, a sucedito un error, inténtelo más tarde.`,
        });
        console.log(e);
      }
    },

    askDisqualification(item, id_user) {
      swal.fire({
          title: "¿Está seguro de descalificar a este usuario?",
          confirmButtonText: `Continuar`,
          denyButtonText: `Cancelar`,
          icon: "question",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.disqualification(item, id_user);
          }
        });
    },
    async disqualification(item, id_user) {
      try {
        this.loading = true;
        const excluidos = await this.$store.getters["tombola/excluidos"];
        //Agregamos en un array los usuarios exluidos , esto para
        //evitar que salgan premiados nuevamente, al volver a generar el nuevo gandor
     
        if (excluidos.length === 0) {
          for (let index = 0; index < this.winners.length; index++) {
            await  this.$store.dispatch("tombola/agregar_excluido", this.winners[index].id);
          }
        } else {
          await this.$store.dispatch("tombola/agregar_excluido", id_user);
        }
 
        const params = {
          country: this.country,
          cutOffDate: this.cutoff_date,
          excluidos: {
            //users_id: Array.from(this.excluidos),
            users_id:await this.$store.getters["tombola/excluidos"],
          },
        };
        

        //se trae a un usuario nuevo excluyendo el que acaban de descalificar
        await this.$store.dispatch("tombola/get", params).then(() => {
          this.winner = this.$store.getters["tombola/get"];
          //se coloca al principio de la lista al nuevo ganador
          if (this.winner.length === 0) {
            swal.fire({
              title: "¡Atención!",
              text: "Ya no quedan más ganadores :( \n ¿Quiere descalificarlo de todos modos?",
              confirmButtonText: `Continuar`,
              denyButtonText: `Cancelar`,
              icon: "question",
              showCancelButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
               //se elimna el array, al usuario que seleccionaron
                this.winners.splice(item, 1);
              }
            });
          }else{
            //se elimna el array, al usuario que seleccionaron
            this.winners.splice(item, 1);
            this.winners.unshift(this.winner);
          }

        }); 
        this.loading = false;
      } catch (error) {
        console.log('====================================');
        console.log(error);
        console.log('====================================');
        await this.$store.dispatch("error/sendError", {
            plataforma: "Tombola del éxito / Clavulin",
            metodo: "Disqualification winner for verify",
            error: error.response,
            data: JSON.stringify(this.winner)
          });
        this.loading = false;
        this.$notify({
          type: "danger",
          message: `Lo sentimos, a sucedido un error, inténtelo más tarde.`,
        });
        console.log(e);
      }
    },

    askPostWinners() {
      swal
        .fire({
          title: "¿Está seguro de guradar estos ganadores para su posterior verificación para este país?",
          confirmButtonText: `Continuar`,
          denyButtonText: `Cancelar`,
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.postWinners();
          }
        });
    },

    async postWinners() {
      this.loading = true;
      try {
        let winneres = {
          winners: this.winners,
          country: this.country,
          phase: this.cutoff_date,
        };
        await this.$store.dispatch("tombola/save", winneres).then(() => {
          let respuesta = this.$store.getters["tombola/saveWinners"];

          if (respuesta.get.result === "success") {
            this.winners.length = 0;
            //this.excluidos.length = 0;
            this.$store.dispatch("tombola/vaciar_excluidos");
            this.$notify({
              type: "success",
              message: "Ganadores almacenados exitosamente, para su posterior verificación.",
            });
          } else {
           this.$store.dispatch("error/sendError", {
                plataforma: "Tombola del éxito / Clavulin",
                metodo: "Post winners for verify",
                error: respuesta,
                data: JSON.stringify(this.winners)
              });
            this.$notify({
              type: "danger",
              message: "Ha ocurrido un error al guardar los ganadores.",
            });
          }
        });
        this.loading = false;
      } catch (error) {
        console.log('====================================');
        console.log(error);
        console.log('====================================');
        await this.$store.dispatch("error/sendError", {
            plataforma: "Tombola del éxito / Clavulin",
            metodo: "Post winners",
            error: error.response,
            data: JSON.stringify(this.winners)
          });
        this.loading = false;
        this.$notify({
              type: "danger",
              message: "Ha ocurrido un error al guardar los ganadores.",
            });
        
      }
    },
  },
  created() {
    this.getCountries();
    this.getCutOffDate();
    //this.getWinners();
  },
};
</script>
<style scoped>

  .form-group {
    height: 45px !important;
  }
  .tam-img {
      width: 200px !important;
    }

      .form-group {
      margin-bottom: 0rem;
  }
  #tx-trasparente .form-group label {
    opacity: 0 !important;
  }

</style>
